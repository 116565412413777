
const config: IConfig = {
  serve: 'http://rap.unovo.com.cn:8888',
  keys: ['some secret hurr'],
  session: {
    key: 'koa:sess',
  },
}

export default config
